import {
  type ComponentType,
  type FC,
  lazy,
  type LazyExoticComponent,
  Suspense,
} from 'react'

import { BrandIcon } from 'base-ui'

/**
 * Use `lazy` instead of `dynamic` because in Pages Router with React 18 we have hydration error
 */
const BrandIconElectronicPrescription = lazy(
  async () => import('@redteclab/brand-icons/BrandIconElectronicPrescription'),
)

const BrandIconExtraRedPoints = lazy(
  async () => import('@redteclab/brand-icons/BrandIconExtraRedPoints'),
)

const BrandIconVoucher = lazy(
  async () => import('@redteclab/brand-icons/BrandIconVoucher'),
)

const BrandIconApp = lazy(
  async () => import('@redteclab/brand-icons/BrandIconApp'),
)

const BrandIconSale = lazy(
  async () => import('@redteclab/brand-icons/BrandIconSale'),
)

const BrandIconEuro = lazy(
  async () => import('@redteclab/brand-icons/BrandIconEuro'),
)

const BrandIconNowDelivery = lazy(
  async () => import('@redteclab/brand-icons/BrandIconNowDelivery'),
)

const BrandIconPrescriptionBonus = lazy(
  async () => import('@redteclab/brand-icons/BrandIconPrescriptionBonus'),
)

const BrandIconCollectRedPoints = lazy(
  async () => import('@redteclab/brand-icons/BrandIconCollectRedPoints'),
)

const BrandIconRegularPrescription = lazy(
  async () => import('@redteclab/brand-icons/BrandIconRegularPrescription'),
)

const BrandIconPlantbasedProduct = lazy(
  async () => import('@redteclab/brand-icons/BrandIconPlantbasedProduct'),
)

const ICON_MAP: Partial<Record<string, LazyExoticComponent<ComponentType>>> = {
  BrandIconApp,
  BrandIconCollectRedPoints,
  BrandIconElectronicPrescription,
  BrandIconEuro,
  BrandIconExtraRedPoints,
  BrandIconNowDelivery,
  BrandIconPlantbasedProduct,
  BrandIconPrescriptionBonus,
  BrandIconRegularPrescription,
  BrandIconSale,
  BrandIconVoucher,
}

export const StoryblokIcon: FC<{
  className?: string
  color?: 'brand' | 'secondary'
  icon: string | number | undefined
  size?: 's' | 'l' | 'm'
}> = ({ className, color, icon, size }) => {
  if (typeof icon !== 'string') {
    return null
  }

  const SvgIcon = ICON_MAP[icon]

  if (!SvgIcon) {
    return null
  }

  return (
    <Suspense>
      <BrandIcon
        className={className}
        color={color}
        icon={SvgIcon}
        size={size}
      />
    </Suspense>
  )
}
