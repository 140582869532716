import { storyblokEditable } from '@storyblok/react/rsc'
import { type FC } from 'react'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokServicesListItemType } from '../../model/storyblokTypes.generated'
import { StoryblokIcon } from '../StoryblokIcon/StoryblokIcon'

export const StoryblokServicesListItem: FC<
  StoryblokComponentProps<StoryblokServicesListItemType>
> = ({ blok }) => {
  const WrapperComponent = blok.href ? 'a' : 'div'

  return (
    <li
      {...storyblokEditable(blok)}
      className="relative flex flex-row py-2 text-xs after:absolute after:-bottom-px after:w-full after:border-b after:border-b-dark-primary-min"
    >
      <WrapperComponent className="flex w-full items-center" href={blok.href}>
        <StoryblokIcon className="mr-3 shrink-0" icon={blok.icon} size="s" />
        <div className="flex flex-col">
          <span className="font-medium text-dark-primary-max">
            {blok.title}
          </span>
          <span className="text-dark-primary-medium">{blok.description}</span>
        </div>
      </WrapperComponent>
    </li>
  )
}
